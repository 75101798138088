import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyDqb7uKar4KJ8sUsZJWulU-1NfUoXavmNg",
    authDomain: "travelswithluci-91494.firebaseapp.com",
    databaseURL: "https://travelswithluci-91494.firebaseio.com",
    projectId: "travelswithluci-91494",
    storageBucket: "travelswithluci-91494.appspot.com",
    messagingSenderId: "477273883671"
};
firebase.initializeApp(config)


const database = firebase.database();


export function onLuciLocation(cb) {
    const ref = database.ref("luci/location");
    ref.on("value", (snapshot) => {
        cb(snapshot.val());
    });
}


const cache = {
    directions: null
}

async function getDirections(origin, destination) {
  if (!cache.directions) {
      const key = `directions`;
      const snapshot = await firebase.database().ref(key).once('value');
      cache.directions = snapshot.val();
  }
  
   return cache.directions[`${origin.place_id}|${destination.place_id}`];
}

async function stopByOriginId(originId, places, route) {
  const destinationId = route[originId];

  const origin = places[originId];
  const destination = places[destinationId]

  const directions = await getDirections(origin, destination);

  return { origin, destination, directions };
}

export async function getRoute() {
  let snapshot = await firebase.database().ref('/route').once('value');

  const route = snapshot.val();
  if (!route) {
      return null;
  }

  const origins = Object.keys(route)
  const destinations = Object.values(route);

  // find the first stop by finding an origin that is no one else's destination.
  const start = origins.find(o => !destinations.includes(o));

  snapshot = await firebase.database().ref('/places').once('value');
  const places = snapshot.val(); 

  const ordered = [];
  let current = start;
  while (route[current]) {
      const next = route[current];
      ordered.push(await stopByOriginId(current, places, route))
      current = next;
  }

  return ordered;
}

export function closestStop(route, location) {
    if (!route || !location) {
        return null;
    }

    const { google } = window;

    let closest;
    let minDistance = Number.MAX_VALUE;
    const luciPos = new google.maps.LatLng(location);
    for (const stop of route) {
        const pos = new google.maps.LatLng(stop.origin.location);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(luciPos, pos);
        if (distance < minDistance) {
            closest = stop.origin;
            minDistance = distance;
        }
    }

    return closest;
}