import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

import { closestStop } from './Database'

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDqb7uKar4KJ8sUsZJWulU-1NfUoXavmNg&libraries=geometry",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
    const { route, luci } = props;
    const markers = [];
    const polylines = [];
        console.log(luci);


    const { google } = window;

    if (route) {
        let currentLocation = closestStop(route, luci);

        let traveled = true;
        for (const stop of route) {
            const { origin, directions: { polyline } } = stop;

            const closest = currentLocation && origin.place_id === currentLocation.place_id;

            if (closest) {
                traveled = false;
            }
            const color = traveled || closest ? '#02a5a5' : '#aaaaaa';
            const opacity = traveled ? .8: .6;

            const r =  closest ? 10 : 5;


            var icon = {
                path: `M-${r},0a${r},${r} 0 1,0 ${r*2},0a${r},${r} 0 1,0 -${r*2},0`,
                fillColor: color,
                fillOpacity: opacity,
                anchor: new google.maps.Point(0,0),
                strokeWeight: 0,
                scale: 1
            };

            var line = {
                strokeOpacity: opacity,
                strokeColor: color
            };
            
            markers.push(<Marker key={`marker:${origin.place_id}`} position={origin.location} icon={icon}/>);

            const path = google.maps.geometry.encoding.decodePath(polyline.points)
            polylines.push(<Polyline key={`path:${origin.place_id}`} path={path} options={line}/>);

            if (luci) {
               
            }
        }
    }

  return(
    <GoogleMap
        defaultZoom={5}
        defaultCenter={{lat: 39.622086, lng: -97.189681}}
        options={{
            fullscreenControl: false,
            mapTypeControl: false,
        }}
    >
        {markers}
        {polylines}
        {luci && <Marker position={luci} icon="https://travelswithluci.com/trailer.png"/>}
    </GoogleMap>
    )
})

export default MyMapComponent;