import React, { Component } from 'react';
import './App.scss';
import MapComponent from './Map'
import Header from './Header'
import ReactGA from 'react-ga';

import { getRoute, onLuciLocation } from './Database';

class App extends Component {

  constructor(props) {
    ReactGA.initialize('UA-138664680-1');
    ReactGA.pageview(
      `${window.location.pathname}${window.location.search}`
    );
    super(props);
    this.initalizeDatabase();
    this.state = {
      route: null,
      luci: null,
    };
  }

  async initalizeDatabase() {
    const route = await getRoute()
    this.setState({ route })
    onLuciLocation(luci => {
      this.setState({ ...this.state, luci});
    });
  }

  render() {
    return (
      <div className="App">
        <Header route={this.state.route} route={this.state.route} luci={this.state.luci} ></Header>
        <MapComponent className="Map" route={this.state.route} luci={this.state.luci} />
      </div>
    );
  }
}

export default App;
