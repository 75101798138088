import React, { Component } from 'react';
import { closestStop } from './Database'

export default class Header extends Component {
    render() {
        const { route, luci } = this.props;

        let stats = null;
        if(route && luci) {
            const closest = closestStop(route, luci);
            let traveled = true;
            let traveledMeters = 0
            let totalMeters = 0;
            for (const stop of route) {
                if (stop.origin === closest) {
                    traveled = false;
                }
                const meters = stop.directions.distance.value
                totalMeters += meters;

                if (traveled) {
                    traveledMeters += meters;
                }
            }
            const metersToMiles = (meters) => `${Math.floor(meters / 1609.344)}mi`;
            stats = (
                <div className="stats">
                    <span className="traveled">{metersToMiles(traveledMeters)}</span>
                    <span className="total">/{metersToMiles(totalMeters)}</span>
                </div>
            );
        }
        return (
            <div className="Header">
                <h1>Travels with Luci</h1>
                {stats}
            </div>
        )
    }
}